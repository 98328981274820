const footerData = [
  {
    heading: 'Product',
    links: [
      {
        label: 'Home',
        href: '/',
      },
      {
        label: 'Platform Features',
        href: '/product',
      },
      {
        label: 'Pricing',
        href: '/pricing',
      },
      {
        label: 'Record',
        href: '/product/video-recording',
      },
      {
        label: 'Webinar Hosting',
        href: '/product/webinar-hosting',
      },
      {
        label: 'Editing',
        href: '/product/video-editing',
      },
      {
        label: 'Collaboration',
        href: '/product/collaboration',
      },
      {
        label: 'Hosting',
        href: '/product/hosting',
      },
      {
        label: 'Management',
        href: '/product/video-management',
      },
      {
        label: 'Player',
        href: '/product/player',
      },
      {
        label: 'Embedding',
        href: '/product/embeds',
      },
      {
        label: 'Lead Generation',
        href: '/product/lead-generation',
      },
      {
        label: 'Channels',
        href: '/product/channels',
      },
      {
        label: 'Localization',
        href: '/product/localize',
      },
      {
        label: 'SEO',
        href: '/product/video-seo',
      },
      {
        label: 'Engagement',
        href: '/product/interactive-video',
      },
      {
        label: 'Wistia API',
        href: '/product/platform',
      },
      {
        label: 'Podcasts',
        href: '/product/podcasting',
      },
      {
        label: 'Analytics',
        href: '/product/analytics',
      },
      {
        label: 'Marketing Automation',
        href: '/product/marketing-automation',
      },
      {
        label: 'All Integrations',
        href: '/integrations',
      },
    ],
  },
  {
    heading: 'Learning Center',
    links: [
      {
        label: 'Blog',
        href: '/learn',
      },
      {
        label: 'Events',
        href: '/events',
      },
      {
        label: 'Customer Stories',
        href: '/learn/showcase',
      },
      {
        label: 'Guide to Video Marketing',
        href: '/about/video-marketing-guide',
      },
      {
        label: 'State of Video Report',
        href: '/for/state-of-video-report',
      },
      {
        label: 'Asset Library',
        href: '/resources/free-downloads',
      },
    ],
  },
  {
    heading: 'Video Series',
    links: [
      {
        label: 'Talking Too Loud',
        href: '/series/talking-too-loud',
      },
      {
        label: 'Fix My Setup',
        href: '/for/fix-my-setup',
      },
      {
        label: 'Show Business',
        href: '/show-business/episodes',
      },
      {
        label: 'Brandwagon',
        href: '/series/brandwagon',
      },
      {
        label: 'Gear Squad vs Dr. Boring',
        href: '/series/gear-squad',
      },
      {
        label: 'A Better Workplace',
        href: '/series/a-better-workplace',
      },
      {
        label: 'One, Ten, One Hundred',
        href: '/series/one-ten-one-hundred',
      },
    ],
  },
  {
    heading: 'Support',
    links: [
      {
        label: 'Help Center',
        href: '/support',
      },
      {
        label: 'Product Changelog',
        href: 'https://updates.wistia.com/',
      },
      {
        label: 'Status',
        href: 'https://status.wistia.com/',
      },
      {
        label: 'Contact Support',
        href: '/support/contact',
      },
    ],
  },
  {
    heading: 'Company',
    links: [
      {
        label: 'About Us',
        href: '/about',
      },
      {
        label: 'Brand Assets',
        href: '/about/assets',
      },
      {
        label: 'Careers',
        href: '/jobs',
      },
      {
        label: 'Company Values',
        href: '/about/values',
      },
      {
        label: 'DEI & Belonging',
        href: '/about/diversity-equity-inclusion',
      },
      {
        label: 'Partner Program',
        href: '/partner-program',
      },
      {
        label: 'Security',
        href: 'https://security.wistia.com/',
      },
    ],
  },
];

export default footerData;
